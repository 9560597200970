import React from 'react';
import Hero from '../components/Hero';
import { useNavigate } from 'react-router-dom';
import FAQBase from '../components/FAQ';
import ContactForm from '../components/ContactForm';
import MeetTheTeam from '../components/MeetTheTeam';
import Sponsors from '../components/Sponsors';
import AboutUs from '../components/About';

import { useTranslation } from 'react-i18next';

const Home: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="overflow-x-hidden">
            <Hero
                backgroundImage="/bg-2.jpg"
                title=""
                subtitle={t('homeHero')}
                buttonText="Read More"
                onButtonClick={() => navigate('/about')}
            />

            <AboutUs schema="1" />

            <Sponsors schema="light" />

            {/* <MeetTheTeam schema="dark" /> */}

            <FAQBase
                schema="light"
            />
            <ContactForm schema="light" />
        </div>
    );
}

export default Home;
